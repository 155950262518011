import React from 'react';
import './App.css';

function App() {
  return (

      <div className="App">
        <img src={"./logo.svg"} className={"logo"}/>
        <div>
          <h1>Hey there! I'm Pak. 😀</h1>
          <h3>A software engineer & designer, living in Hong Kong.</h3>
          <br />
          <p>On weekdays I make apps for enterprises.</p>
          <p>In my free time, I like to read, go for a walk and make pots.</p>
          <p>If you're looking for help on app development, feel free to <a href={"mailto:pakwlau@gmail.com"}>ping</a> me.</p>
          <br />
          <p>
            <a href={"https://github.com/paklau"} target={"_blank"}>github</a>&emsp;<a href={"https://www.linkedin.com/in/pakwlau/"} target={"_blank"}>linkedIn</a>
          </p>
        </div>
      </div>
  );
}

export default App;
